import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Input} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { admin_uri } from "../../../utils/url";

export default function DeleteAdminModal({datas, callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);

  const handlecall = () => {
    callback();
  }

  const submit = async() => {
    setLoad(true);
    try{
        const res = await axios.get(admin_uri+'/delete'+`?id=${datas.id}`);
        if(res.status === 200){
            toast.success("Амжилттай устгалаа");
            handlecall();
        }
        setLoad(false);
    }catch(err){
        setLoad(false);
        console.log(err);
    }
  }

  return (
    <>
      <Button className="bg-red-500 text-white" size="sm" onClick={onOpen}>Устгах</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Та {datas.username} устгах гэж байна ?</ModalHeader>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Close
                </Button>
                {
                    load?
                    <Button color="primary">
                      Уншиж байна ...
                    </Button>
                    :
                    <Button color="primary" onClick={submit}>
                      Устгах
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
