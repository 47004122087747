import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Input} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { admin_uri } from "../../../utils/url";

export default function AddAdminModal({callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [data, setData] = useState({username: "", password: ""});
  const [load, setLoad] = useState(false);

  const handlecall = () => {
    callback();
  }

  const submit = async() => {
    setLoad(true);
    try{
        const res = await axios.post(admin_uri+'/add', data);
        if(res.status === 200){
            toast.success("Амжилттай нэмлээ");
            handlecall();
        }
        onOpenChange(false);
        setLoad(false);
    }catch(err){
        setLoad(false);
        console.log(err);
    }
  }

  return (
    <>
      <Button size="sm" className="mt-2" onClick={onOpen}>Админ нэмэх</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Хэрэглэгчийн мэдээлэл засварлах</ModalHeader>
              <ModalBody>
                <div>
                    <h1 className="text-sm font-bold font-Roboto mb-1">Админы нэвтрэх нэр</h1>
                    <Input placeholder="" size="sm" value={data.username} onChange={(e)=> setData({...data, username: e.target.value})}/>

                    <h1 className="text-sm font-bold font-Roboto mb-1 mt-3">Нууц үг</h1>
                    <Input placeholder="" size="sm" value={data.password} onChange={(e)=> setData({...data, password: e.target.value})}/>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Close
                </Button>
                {
                    load?
                    <Button color="primary">
                        Уншиж байна ...
                    </Button>
                    :
                    <Button color="primary" onClick={submit}>
                        Нэмэх
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
